import React from "react"
import Helmet from "react-helmet"
import Layout from "../layouts/es"
import SPHeader from "../components/static-pages-layout/static-page-header"
import SPSection from "../components/static-pages-layout/static-page-section"
import SPP from "../components/static-pages-layout/static-page-p"
import decentralized from "../images/decentralized.png"
import decentralizedDark from "../images/decentralized-dark.png"

const AcercaDeDevlandPage = ({ location }) => {
  const langPaths = { en: "/about-devland" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>Acerca de DevLand</title>
        <meta
          name="description"
          content="DevLand es una organización descentralizada autónoma de ingeniería de software"
        />
      </Helmet>
      <SPHeader
        superTitle="Acerca de"
        title="DevLand"
        imageLight={decentralized}
        imageDark={decentralizedDark}
      >
        DevLand es una DAO de ingeniería de software que utiliza tecnología web
        y blockchain para proporcionar a sus miembros un conjunto de servicios
        de acceso a contenido técnico curado y de calidad, acceso formación
        técnica, gestión de contactos profesionales y sociales, búsqueda de
        empleo y desarrollo descentralizado de aplicaciones.
      </SPHeader>
      <SPSection title="Nuestra misión">
        <SPP>
          La misión de DevLand es descentralizar el tejido empresarial del del
          sector de las TI paso a paso, dándole forma horizontal de manera que
          se restaure el equilibrio de poder entre los actores que lo componen.
        </SPP>
        <SPP>
          Queremos democratizar el mundo del desarrollo de software, desde el
          aprendizaje hasta la ejecución del código, pasando por el diseño e
          implementación del software también.
        </SPP>
      </SPSection>
      <SPSection title="Nuestra infraestructura">
        <SPP>Hemos elegido Stellar como nuestra blockchain para empezar.</SPP>
        <SPP>
          En las primeras etapas del proyecto utilizaremos un modelo
          semi-centralizado y Stellar es la herramienta ideal para este tipo de
          escenarios ya que no necesitamos contratos inteligentes completamente
          funcionales en un entorno semi-centralizado, pero podemos
          aprovecharnos de su facilidad de integración, rendimiento y bajos
          costes de transacción.
        </SPP>
      </SPSection>
    </Layout>
  )
}

export default AcercaDeDevlandPage
